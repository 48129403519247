import mapTrack from '../mapper/mapTrack';
import songwhipApi, { SongwhipApiOptions } from '../songwhipApi';
import { ItemConfig } from '../types';

type GetTrackParams = {
  trackId: number;
};

export const getTrackApi = async ({ trackId }: GetTrackParams) => {
  const {
    json: { data },
  } = await songwhipApi(`tracks/${trackId}`);

  return mapTrack(data);
};

type LocalizeTrackParams = {
  trackId: number;
  country: string;
};

export const localizeTrackApi = async ({
  trackId,
  country,
}: LocalizeTrackParams) => {
  const {
    json: { data },
  } = await songwhipApi(`tracks/${trackId}/localize`, {
    method: 'POST',
    body: {
      country,
    },
  });

  return mapTrack(data);
};

type RefreshTrackParams = {
  trackId: number;
  force?: boolean;
  country?: string;
};

export const refreshTrackApi = async ({
  trackId,
  force,
  country,
}: RefreshTrackParams) => {
  const {
    json: { data },
  } = await songwhipApi(`tracks/${trackId}/refresh`, {
    method: 'POST',
    body: {
      force,
      country,
    },
  });

  return mapTrack(data);
};

export const patchTrackConfigApi = async ({
  trackId,
  config,
  lastUpdatedAt,
  showToastOnError,
}: {
  trackId: number;
  config: ItemConfig;

  /**
   * Clients can optionally pass their last known `updatedAt` value
   * so songwhip-api can assert that the record was not modified (ie. has a
   * new `updatedAt` value) since the client last fetched the record.
   * If `updatedAt` is different then it is considered a write conflict
   * and the request will fail.
   */
  lastUpdatedAt?: number;

  showToastOnError?: SongwhipApiOptions['showToastOnError'];
}) => {
  if (!trackId) throw new Error('`trackId` required');

  let path = `tracks/${trackId}/config`;

  if (lastUpdatedAt) {
    path += `?lastUpdatedAt=${lastUpdatedAt}`;
  }

  const {
    json: { data },
  } = await songwhipApi(path, {
    method: 'PATCH',
    body: config,
    showToastOnError,
  });

  return mapTrack(data);
};
