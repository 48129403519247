import mapArtist from '~/app/lib/songwhipApi/mapper/mapArtist';

import songwhipApi, { SongwhipApiOptions } from '../songwhipApi';
import { ItemConfig } from '../types';

export const getArtistApi = async ({
  artistId,
  force = false,
}: {
  artistId: number;
  force?: boolean;
}) => {
  let pathname = `artists/${artistId}`;

  // when the caller wants to bypass any edge-caches we append a cache-busting
  // query param that ensures the record comes from origin.
  if (force) pathname += `?t=${Date.now()}`;

  const {
    json: { data },
  } = await songwhipApi(pathname);

  return mapArtist(data);
};

export const localizeArtistApi = async ({
  artistId,
  country,
}: {
  artistId: number;
  country: string;
}) => {
  const {
    json: { data },
  } = await songwhipApi(`artists/${artistId}/localize`, {
    method: 'POST',
    body: {
      country,
    },
  });

  return mapArtist(data);
};

export const refreshArtistApi = async ({
  artistId,
  country,
  force,
}: {
  artistId: number;
  country?: string;
  force?: boolean;
}) => {
  if (!artistId) throw new Error('`artistId` required');

  const {
    json: { data },
  } = await songwhipApi(`artists/${artistId}/refresh`, {
    method: 'POST',

    body: {
      country,
      force,
    },
  });

  // when an artist is refreshed it can be found to be a duplicate and deleted
  // in this case we just return the replacement and allow the front-end to redirect
  const artist = data.replacedByArtist ? data.replacedByArtist : data;

  return mapArtist(artist);
};

export const patchArtistConfigApi = async ({
  artistId,
  config,
  lastUpdatedAt,
  showToastOnError,
}: {
  artistId: number;
  config: ItemConfig;

  /**
   * Clients can optionally pass their last known `updatedAt` value
   * so songwhip-api can assert that the record was not modified (ie. has a
   * new `updatedAt` value) since the client last fetched the record.
   * If `updatedAt` is different then it is considered a write conflict
   * and the request will fail.
   */
  lastUpdatedAt?: number;

  showToastOnError?: SongwhipApiOptions['showToastOnError'];
}) => {
  if (!artistId) throw new Error('`artistId` required');

  let path = `artists/${artistId}/config`;

  if (lastUpdatedAt) {
    path += `?lastUpdatedAt=${lastUpdatedAt}`;
  }

  const {
    json: { data },
  } = await songwhipApi(path, {
    method: 'PATCH',
    body: config,
    showToastOnError,
  });

  return mapArtist(data);
};

export const hideArtistApi = async ({ artistId }: { artistId: number }) => {
  const {
    json: { data },
  } = await songwhipApi(`artists/${artistId}/hide`, {
    method: 'POST',
  });

  return mapArtist(data);
};
